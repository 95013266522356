<template>
  <AppBanner :url="require('@/assets/image/cover/location.png')" title="聯絡仁大" />
  <AppContentNav v-model="activeId" :items="navs" />
  <div class="container">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeId: 1,
      navs: [
        { id: 1, name: "訪客留言", path: "/contact", routeName: "contact" },
        { id: 2, name: "營運據點", path: "/location", routeName: "location" },
      ],
      locations: [
        { name: "總公司", area: "台北市、新北市、基隆" },
        { name: "雲林辦事處", area: "雲林" },
        { name: "嘉義辦事處", area: "嘉義" },
        { name: "台南辦事處", area: "台南" },
        { name: "桃園辦事處", area: "桃園、中壢 " },
        { name: "高雄辦事處", area: "高雄、屏東" },
        { name: "新竹辦事處", area: "新竹、苗栗" },
        { name: "宜蘭辦事處", area: "宜蘭、羅東 " },
        { name: "台中辦事處", area: "大台中地區" },
        { name: "花蓮辦事處", area: "花蓮" },
        { name: "彰化辦事處", area: "彰化、南投" },
        { name: "台東辦事處", area: "台東" },
      ],
    };
  },
  methods: {
    setActiveId(name) {
      const id = this.navs.find((nav) => nav.routeName === name)?.id;
      this.activeId = id;
    },
  },
  watch: {
    $route(val) {
      this.setActiveId(val.name);
    },
  },
  created() {
    this.setActiveId(this.$route.name);
  },
};
</script>
